import React from "react"
import { Layout } from "../components/Layout"
import { SEO } from "../components/Seo"
import { FeaturedDish } from "../components/FeaturedDish";
import { graphql, Link } from "gatsby";
import { FeaturedMenuListing } from "../components/FeaturedMenuListing";
import { runInThisContext } from "vm";

export interface IAppProps
{
	data: any;
}

interface IHomeState
{
	bookingModalOpen: boolean;
}

export const query = graphql`
query HomepageMenuQuery {
  dimsumJson {
    items {
	  id
      name
      description
    }
  }
  featuredJson {
    items
  }
  dimSumFeatureImage: file(relativePath: {eq: "dishes/dimsum.png"}) {
    childImageSharp {
      fixed {
        src
      }
    }
  }
  metaImage: file(relativePath: {eq: "meta.jpg"}) {
    childImageSharp {
      original{src
      }
    }
  }
  tableAgentImage: file(relativePath: {eq: "tableagent.png"}) {
    childImageSharp {
      fixed {
        src
      }
    }
  }  
  googleReviewsImage: file(relativePath: {eq: "googlereviews.png"}) {
    childImageSharp {
      fixed {
        src
      }
    }
  }
}
`;

export default class HomePage extends React.Component<IAppProps, IHomeState>
{
	constructor(props: IAppProps)
	{
		super(props);

		this.state = {
			bookingModalOpen: false
		};
	}

	private onToggleBookingModal(): void
	{
		this.setState({
			bookingModalOpen: !this.state.bookingModalOpen
		});
	}

	private onRestaurantGuruClicked(event: any): void 
	{
		window.open("https://restaurantguru.com/Oriental-Garden-Northampton", "_blank");
	}

	public render(): JSX.Element
	{
		return (
			<Layout className="homepage">
				<SEO title="Home" image={this.props.data.metaImage.childImageSharp.original.src} />

				<section className="hero">
					<div className="hero-content">
						<div className="animate-fade-in dimsum-feature-2"></div>
						<div className="content content-right">
							<h1>Oriental Garden <br />Chinese Restaurant</h1>
							<p className="lead">
								Experience true Chinese food at its best
								in the heart of Northampton.
							</p>
							<div className="button-group">
								<Link to="/book" title="Book a table" className="btn btn-outline btn-white">
									Book a table
								</Link>
								<a className="btn btn-outline btn-white" href="https://order.orientalgardenrestaurant.co.uk/northampton-chinese-takeaway/menus" title="Order online">
									Order Online
								</a>
							</div>
							<div className="button-group">
								<a className="btn btn-transparent btn-white btn-single" href="tel:01604 602818" title="Phone us on 01604 602818">
									Or phone +44(0) 1604 602818
								</a>
							</div>
						</div>
					</div>
				</section>

				<section className="menu menu-featured">
					<div className="content">
						<div className="menu">
							<div className="menu-feature">
								<h1>Experience <span>Authentic Dim Sum</span></h1>
							</div>

							<FeaturedMenuListing
								menuItems={this.props.data.dimsumJson.items}
								featuredItems={this.props.data.featuredJson.items}
							/>

							<div className="button-group">
								<Link to="/dimsum" title="View full Dim Sum Menu" className="btn btn-primary">
									View full Dim Sum Menu
								</Link>
							</div>
						</div>
					</div>
				</section>

				<FeaturedDish />

				<section className="find-us">
					<div className="find-us-background"></div>
					<div className="content">
						<h1 className="text-uppercase">Find us</h1>
						<p className="lead">
							We're only a stone's throw from the <br />
							town market square and bus station.
						</p>
						<div className="button-group">
							<Link to="/book" title="Book a table" className="btn btn-primary btn-red">
								Book a table
							</Link>
							<a className="btn btn-transparent" href="tel:01604 602818">
								Or phone +44(0) 1604 602818
							</a>
						</div>
					</div>
				</section>

				<section className="map">
					<iframe title="Google maps" width="600" height="450" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.2355402764947!2d-0.9002941840085863!3d52.23910526476006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48770edd6e2a027d%3A0x69fa12b9e55f95f3!2sOriental%20Garden%20Restaurant!5e0!3m2!1sen!2suk!4v1566817898847!5m2!1sen!2suk">
					</iframe>
				</section>

				<section className="featured">
					<div className="content">
						<div className="featured-sites">
							<div className="featured-site table-agent">
								<a href="https://tableagent.com/northamptonshire/oriental-garden/" target="_blank" title="Visit Table Agent">
									<img src={this.props.data.tableAgentImage.childImageSharp.fixed.src} />
								</a>
							</div>

							<div className="featured-site">
								<link href="https://awards.infcdn.net/sq5_n.css" rel="stylesheet" />
								<div id="rest_sq5" onClick={this.onRestaurantGuruClicked.bind(this)} style={{ margin: "auto" }}>
									<a href="https://restaurantguru.com/Oriental-Garden-Northampton" className="sq_top" target="_blank">Oriental Garden</a>
									<div className="sq_img" style={{
										background: "url('https://awards.infcdn.net/img/star_white.svg')",
										backgroundRepeat: "no-repeat", backgroundPosition: "center"
									}}>
									</div>
									<span>Recommended on</span>
									<a href="https://restaurantguru.com" className="sq_bot" target="_blank">Restaurant Guru 2021</a>
								</div>
							</div>

							<div className="featured-site">
								<a href="https://www.google.com/search?q=oriental+garden+restaurant+northampton" target="_blank" title="Visit Google Reveiws">
									<img src={this.props.data.googleReviewsImage.childImageSharp.fixed.src} alt="Google reviews - Oriental Garden Northampton" />
								</a>
							</div>
						</div>
					</div>
				</section>
			</Layout >
		);
	}
}