import { Link } from "gatsby"
import React from "react"

export class FeaturedDish extends React.Component<{}, {}>
{
    constructor(props: any)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <div className="content-fluid featured-dishes-row">
                <div className="content">
                    <div className="featured-dishes">
                        <div className="dish-item dimsum">
                            <Link to="/dimsum" title="Dim Sum" className="btn btn-secondary btn-lg">
                                Dim Sum - 點心
                            </Link>
                        </div>

                        <div className="dish-item alacart">
                            <Link to="/a-la-carte" title="A La Carte" className="btn btn-secondary btn-lg">
                                À la Carte - 單點
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}