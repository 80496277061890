import { Link } from "gatsby"
import React from "react"
import { IMenuItem } from "../Menu";

export interface IMenuListingProps
{
    menuItems: Array<IMenuItem>;
    featuredItems: Array<number>;
}

export class FeaturedMenuListing extends React.Component<IMenuListingProps, {}>
{
    constructor(props: IMenuListingProps)
    {
        super(props);
    }

    private buildMenuItems(): Array<JSX.Element>
    {
        var menuItems = [];

        for (var i = 0; i < this.props.menuItems.length; i++)
        {
            var menuItem = this.props.menuItems[i];

            if (this.props.featuredItems.indexOf(menuItem.id) == -1)
                continue;

            menuItems.push(
                <div key={i} className="menu-item">
                    <h3>{menuItem.name}</h3>
                    <p>
                        {menuItem.description}
                    </p>
                </div>
            );
        }

        return menuItems;
    }

    public render(): JSX.Element
    {
        return (
            <div className="menu-items">
                {this.buildMenuItems()}
            </div>
        );
    }
}